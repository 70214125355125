/*
 * @Author: zhangguoliang
 * @Date: 2021-09-26 10:38:15
 * @LastEditors: liuxia
 * @LastEditTime: 2022-09-17 12:03:11
 */

import { Raw } from '@/interface';
import { message } from 'ant-design-vue';
import Vue from 'vue';

export function cloneWithTemplate(target: Raw, template?: Raw): Raw {
  if (!template) return target;
  return Object.keys(template).reduce<Raw>((prev, curr) => {
    prev[curr] = target[curr] || template[curr];
    return prev;
  }, {});
}

/**
 * @description: 校验文件格式
 * @param {*} fileName 文件名称
 * @param {*} type 文件类型
 * @return {*}
 */
export const checkFileType = (fileName, ...arg) => {
  const fileArr = arg.length ? arg.map((i) => i.toLowerCase()) : ['zip'];
  if (!fileName) return false;
  const fileType = fileName.substring(fileName.lastIndexOf('.') + 1);
  return fileArr.includes(fileType.toLowerCase());
};
/**
 * @description: 下载文件流
 * @param {*} data 文件流
 * @param {*} fileName 文件名
 * @param {*} fileType 文件类型
 * @return {*}
 */
export function downloadFile(
  data: ArrayBuffer,
  fileName = new Date().toString(),
  fileType = 'excel'
): void {
  console.log('🚀 获取之后a', data);
  let type = '';
  switch (fileType) {
    case 'excel':
      type =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'pdf':
      type = 'application/pdf';
      break;
    default:
      type = 'application/zip';
      break;
  }

  const resBlob = new Blob([data]);
  const reader = new FileReader();
  reader.readAsText(resBlob, 'utf-8');
  reader.onload = () => {
    try {
      const result = JSON.parse(reader.result as string);
      message.error(result.message);
      return '';
    } catch (error) {
      const blob = new Blob([data], { type });
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const elink = document.createElement('a') as HTMLAnchorElement;
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = window.URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        window.URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, fileName);
        }
      }
    }
  };
}

/**
 * @description: 下载文件
 * @param {*} data 文件
 * @param {*} fileName 文件名
 * @param {*} fileType 文件类型
 * @return {*}
 */
export function downloadFileByUrl(
  data: string,
  fileName = new Date().toString(),
  fileType = 'excel'
): void {
  console.log('🚀 获取之后a', data);
  let type = '';
  switch (fileType) {
    case 'excel':
      type =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'pdf':
      type = 'application/pdf';
      break;
    default:
      type = 'application/zip';
      break;
  }
  // 非IE下载
  const elink = document.createElement('a') as HTMLAnchorElement;
  elink.download = fileName;
  elink.style.display = 'none';
  elink.href = data;
  elink.click();
}

/**
 * 两数相加之和,解决精度丢失问题
 * @param arg1
 * @param arg2
 * @returns number
 */
export function accAdd(arg1: number, arg2: number): string {
  if (!arg1) {
    arg1 = 0;
  }
  let r1, r2;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  const c = Math.abs(r1 - r2);
  const m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
    const cm = Math.pow(10, c);
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace('.', ''));
      arg2 = Number(arg2.toString().replace('.', '')) * cm;
    } else {
      arg1 = Number(arg1.toString().replace('.', '')) * cm;
      arg2 = Number(arg2.toString().replace('.', ''));
    }
  } else {
    arg1 = Number(arg1.toString().replace('.', ''));
    arg2 = Number(arg2.toString().replace('.', ''));
  }
  return ((arg1 + arg2) / m).toFixed(2);
}

/**
 * 两数相减之和,解决精度丢失问题
 * @param arg1
 * @param arg2
 * @returns number
 */
export function subtract(arg1: number, arg2: number): string {
  if (isNaN(arg1) || isNaN(arg2)) return '';
  let r1, r2;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  const m = Math.pow(10, Math.max(r1, r2));
  const n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

/**
 * 将价格转为大写金额
 * @param amount
 * @constructor
 */
export function AmountLtoU(amount) {
  if (isNaN(amount) || amount >= 1000000000000) return '无效金额！'; //数值最大不超过1万亿
  const sPrefix = amount < 0 ? '欠' : ''; //将负号‘-’显示成汉字‘欠’
  let sAmount = Math.abs(amount).toFixed(2); //格式化成两位小数格式（两位小数对应“'角”和“分”）
  let sUnit = '仟佰拾亿仟佰拾万仟佰拾元角分'; //14个权位单位
  const sCapital = '零壹贰叁肆伍陆柒捌玖'; //10个大写数字
  sAmount = sAmount.replace('.', ''); //去除小数点（使数字与权位对应）
  sUnit = sUnit.substr(sUnit.length - sAmount.length); //截取权位
  let sOutput = '';
  for (let i = 0, len = sAmount.length; i < len; i++) {
    //拼接数字与权位单位
    sOutput +=
      sCapital.substr(Number(sAmount.substr(i, 1)), 1) + sUnit.substr(i, 1);
  }
  return (
    sPrefix +
    sOutput
      .replace(/零角零分$/, '整')
      .replace(/零[仟佰拾]/g, '零')
      .replace(/零{2,}/g, '零')
      .replace(/零([亿|万])/g, '$1')
      .replace(/零+元/, '元')
      .replace(/亿零{0,3}万/, '亿')
      .replace(/^元/, '零元')
  );
}

/**
 * 获取滚动条宽度
 * @returns number
 */
export function getScrollWidth() {
  let noScroll = 0,
    scroll = 0;
  const oDiv = document.createElement('DIV') as HTMLDivElement;
  oDiv.style.cssText =
    'position:absolute; top:-1000px; width:100px; height:100px; overflow:hidden;';
  oDiv.className = 'scrollbar';
  noScroll = (document.getElementById('ysf_body') as HTMLElement).appendChild(
    oDiv
  ).clientWidth;
  oDiv.style.overflowY = 'scroll';
  scroll = oDiv.clientWidth;
  (document.getElementById('ysf_body') as HTMLElement).removeChild(oDiv);
  return noScroll - scroll;
}

export function getNumber(value, num = 2) {
  const reg = new RegExp('^[0-9]*(' + `\.` + '?[0-9]{0,' + num + '})', 'g');
  const val = ('' + value)
    .replace(/[^\d^\.]+/g, '')
    .replace(/^0+(\d)/, '$1')
    .replace(/^\./, '0.')
    .match(reg);
  if (val?.length) return val[0];
  return '';
}

export function setTableHeaderWidth(className: string): void {
  console.log('============setTableHeaderWidth', className);
  Vue.nextTick(() => {
    const scrollWidth = getScrollWidth();
    const bodyWidth =
      (document.querySelector(className) as HTMLElement)?.clientWidth -
      scrollWidth;
    const tableEl = document.querySelector(
      `${className} .ant-table-header > table`
    ) as HTMLTableElement;

    if (tableEl) {
      tableEl.style.width = bodyWidth + 'px';
      const bodyEl = document.querySelector(
        `${className} .ant-table-body table`
      ) as HTMLTableElement;
      bodyEl.style.width = bodyWidth + 'px';
    }
  });
}
